/* eslint-disable react/jsx-props-no-spreading */
import {
    Alert,
    // Autocomplete,
    Box,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    Grid,
    InputAdornment,
    Paper,
    Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Background from '../assets/images/registry-bg.png';
import {
    ADULT_EARLY_PRICE,
    ADULT_REGULAR_PRICE,
    CHILDREN_EARLY_PRICE,
    CHILDREN_REGULAR_PRICE,
    EARLY_BIRD_DATE,
    SAYONARA_PRICE_PER_PERSON,
    TSHIRT_PRICE,
} from '../constants';
import useRegister, { RegisterValues } from '../hooks/useRegister';
import { registerSchema } from '../validation';

const GRADES = [
    ...Array.from({ length: 10 }, (_, i) => `${10 - i}th Kyu`),
    ...Array.from({ length: 10 }, (_, i) => `${i + 1}th Dan`),
];

// const TSHIRT_SIZES = [
//     '6/8 year',
//     '8/10 year',
//     '10/12 year',
//     '12/14 year',
//     'S',
//     'M',
//     'L',
//     'XL',
//     'XXL',
// ];

type RegistrationStatus = {
    success: boolean;
    message: string | undefined;
};

const Register = () => {
    const mutation = useRegister((success, message) => {
        setRegistrationStatus({ success, message });
    });
    const [registrationStatus, setRegistrationStatus] =
        useState<RegistrationStatus>({
            success: false,
            message: undefined,
        });

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isValid,
        setFieldValue,
    } = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            birthdate: null,
            city: '',
            country: '',
            grade: '',
            sensei_name: '',
            sayonaraParty: undefined,
            tshirtSize: undefined,
            tshirtQuantity: 0,
            danGrading: false,
            participationType: '',
            totalPrice: 0,
        },
        validationSchema: registerSchema,
        onSubmit: (newValues: RegisterValues) => {
            mutation.mutate(newValues);
        },
    });

    useEffect(() => {
        const currentDate = new Date();
        const earlyBirdDate = new Date(EARLY_BIRD_DATE);
        let basePrice = 0;

        if (values.participationType === 'Adult') {
            basePrice =
                currentDate < earlyBirdDate
                    ? ADULT_EARLY_PRICE
                    : ADULT_REGULAR_PRICE;
        } else if (values.participationType === 'Children') {
            basePrice =
                currentDate < earlyBirdDate
                    ? CHILDREN_EARLY_PRICE
                    : CHILDREN_REGULAR_PRICE;
        }

        const tshirtPrice = values.tshirtSize
            ? TSHIRT_PRICE * (values.tshirtQuantity || 0)
            : 0;
        const sayonaraPrice = values.sayonaraParty
            ? values.sayonaraParty * SAYONARA_PRICE_PER_PERSON
            : 0;
        setFieldValue('totalPrice', basePrice + tshirtPrice + sayonaraPrice);
    }, [
        values.participationType,
        values.tshirtSize,
        values.tshirtQuantity,
        values.sayonaraParty,
        setFieldValue,
    ]);

    const isFormTouched = Object.keys(touched).length > 0;
    const disabled = mutation.isLoading || !isValid || !isFormTouched;

    const isDanGradingAvailable = GRADES.indexOf(values.grade) >= 9;

    return (
        <Box
            id="register"
            sx={{
                height: { xs: 'auto', md: 'calc(100vh - 64px)' },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                position: 'relative',
                minHeight: 'calc(100vh - 56px)',
            }}
        >
            <Box
                sx={{
                    backgroundImage: { xs: 'none', md: `url(${Background})` },
                    backgroundColor: '#2f272c',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1,
                }}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Container
                    maxWidth={registrationStatus.success ? 'xs' : 'md'}
                    sx={{
                        '& .MuiFormControl-root': {
                            my: theme => theme.spacing(0.5),
                        },
                        '& .MuiInputBase-root': {
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            height: { xs: '40px', sm: 'auto' },
                        },
                        '& .MuiInputBase-input': {
                            padding: { xs: '8px 14px', sm: '16.5px 14px' },
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            transform: {
                                xs: 'translate(14px, 11px) scale(1)',
                                sm: 'translate(14px, 16px) scale(1)',
                            },
                            '&.MuiInputLabel-shrink': {
                                transform: {
                                    xs: 'translate(14px, -6px) scale(0.75)',
                                    sm: 'translate(14px, -6px) scale(0.75)',
                                },
                            },
                        },
                        '& .MuiMenuItem-root': {
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            minHeight: { xs: '32px', sm: 'auto' },
                        },
                        '& .MuiSelect-select': {
                            padding: { xs: '8px 14px', sm: '16.5px 14px' },
                        },
                    }}
                >
                    {registrationStatus.success && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '90vh',
                            }}
                        >
                            <Alert
                                severity="success"
                                sx={{ marginBottom: 2, fontSize: '1rem' }}
                            >
                                {registrationStatus.message}
                                <p>
                                    Please check your email for the
                                    confirmation,{' '}
                                </p>
                                <p>
                                    The Gasshuku&apos;s registration will be
                                    fulfilled after your payment&apos;s
                                    validation.
                                </p>
                            </Alert>
                        </Box>
                    )}
                    {!registrationStatus.success && (
                        <Paper
                            elevation={3}
                            sx={{
                                padding: 3,
                                marginTop: 3,
                                position: 'relative',
                                marginBottom: { xs: 4, md: 0 },
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                Gasshuku registration
                            </Typography>
                            {mutation.isLoading && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor:
                                            'rgba(255, 255, 255, 0.5)',
                                        zIndex: 1000,
                                        left: 0,
                                        top: 0,
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={{ xs: 0, sm: 1 }}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            type="text"
                                            name="firstName"
                                            label="First Name *"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                            error={
                                                touched.firstName &&
                                                Boolean(errors.firstName)
                                            }
                                            helperText={
                                                touched.firstName &&
                                                errors.firstName
                                            }
                                            fullWidth
                                        />
                                        <TextField
                                            type="text"
                                            name="lastName"
                                            label="Last Name *"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lastName}
                                            error={
                                                touched.lastName &&
                                                Boolean(errors.lastName)
                                            }
                                            helperText={
                                                touched.lastName &&
                                                errors.lastName
                                            }
                                            fullWidth
                                        />
                                        <TextField
                                            type="email"
                                            name="email"
                                            label="Email *"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            error={
                                                touched.email &&
                                                Boolean(errors.email)
                                            }
                                            helperText={
                                                touched.email && errors.email
                                            }
                                            fullWidth
                                            margin="normal"
                                        />
                                        <Box my={-0.5}>
                                            <FormControl fullWidth>
                                                <DesktopDatePicker
                                                    label="Birthdate *"
                                                    value={values.birthdate}
                                                    onChange={newValue => {
                                                        setFieldValue(
                                                            'birthdate',
                                                            newValue
                                                                ? moment(
                                                                      newValue,
                                                                  )
                                                                : null,
                                                        );
                                                    }}
                                                    format="DD/MM/YYYY"
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            variant: 'outlined',
                                                            error:
                                                                touched.birthdate &&
                                                                Boolean(
                                                                    errors.birthdate,
                                                                ),
                                                            helperText:
                                                                touched.birthdate &&
                                                                errors.birthdate,
                                                        },
                                                    }}
                                                />
                                            </FormControl>
                                        </Box>
                                        <TextField
                                            type="text"
                                            name="city"
                                            label="City *"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.city}
                                            error={
                                                touched.city &&
                                                Boolean(errors.city)
                                            }
                                            helperText={
                                                touched.city && errors.city
                                            }
                                            fullWidth
                                            margin="normal"
                                        />
                                        <TextField
                                            type="text"
                                            name="country"
                                            label="Country *"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.country}
                                            error={
                                                touched.country &&
                                                Boolean(errors.country)
                                            }
                                            helperText={
                                                touched.country &&
                                                errors.country
                                            }
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Grade *</InputLabel>
                                            <Select
                                                name="grade"
                                                label="Grade"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.grade}
                                                error={
                                                    touched.grade &&
                                                    Boolean(errors.grade)
                                                }
                                            >
                                                {GRADES.map(grade => (
                                                    <MenuItem
                                                        key={grade}
                                                        value={grade}
                                                    >
                                                        {grade}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            type="text"
                                            name="sensei_name"
                                            label="Sensei Name *"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.sensei_name}
                                            error={
                                                touched.sensei_name &&
                                                Boolean(errors.sensei_name)
                                            }
                                            helperText={
                                                touched.sensei_name &&
                                                errors.sensei_name
                                            }
                                            fullWidth
                                        />
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                Participation Type *
                                            </InputLabel>
                                            <Select
                                                name="participationType"
                                                label="Participation Type"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.participationType}
                                                error={
                                                    touched.participationType &&
                                                    Boolean(
                                                        errors.participationType,
                                                    )
                                                }
                                            >
                                                <MenuItem value="Adult">
                                                    Adult
                                                </MenuItem>
                                                <MenuItem value="Children">
                                                    Child
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/* <TextField
                                            type="number"
                                            name="sayonaraParty"
                                            label="Sayonara Party (Number of Persons)"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.sayonaraParty}
                                            error={
                                                touched.sayonaraParty &&
                                                Boolean(errors.sayonaraParty)
                                            }
                                            helperText={
                                                touched.sayonaraParty &&
                                                errors.sayonaraParty
                                            }
                                            fullWidth
                                        /> */}
                                        {/* <Box mt={-0.5} mb={0.5}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    options={TSHIRT_SIZES}
                                                    getOptionLabel={option =>
                                                        option
                                                    }
                                                    value={
                                                        values.tshirtSize ||
                                                        null
                                                    }
                                                    onChange={(_, newValue) => {
                                                        setFieldValue(
                                                            'tshirtSize',
                                                            newValue ||
                                                                undefined,
                                                        );
                                                        if (!newValue) {
                                                            setFieldValue(
                                                                'tshirtQuantity',
                                                                0,
                                                            );
                                                        } else if (
                                                            values.tshirtQuantity ===
                                                            0
                                                        ) {
                                                            setFieldValue(
                                                                'tshirtQuantity',
                                                                1,
                                                            );
                                                        }
                                                    }}
                                                    onBlur={handleBlur}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            name="tshirtSize"
                                                            label="T-Shirt Size"
                                                            error={
                                                                touched.tshirtSize &&
                                                                Boolean(
                                                                    errors.tshirtSize,
                                                                )
                                                            }
                                                            helperText={
                                                                touched.tshirtSize &&
                                                                errors.tshirtSize
                                                            }
                                                        />
                                                    )}
                                                    clearOnEscape
                                                />
                                            </FormControl>
                                        </Box> */}
                                        {values.tshirtSize && (
                                            <Box mt={-1}>
                                                <TextField
                                                    type="number"
                                                    name="tshirtQuantity"
                                                    label="T-Shirt Quantity"
                                                    value={
                                                        values.tshirtQuantity
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        touched.tshirtQuantity &&
                                                        Boolean(
                                                            errors.tshirtQuantity,
                                                        )
                                                    }
                                                    helperText={
                                                        touched.tshirtQuantity &&
                                                        errors.tshirtQuantity
                                                    }
                                                    fullWidth
                                                    InputProps={{
                                                        inputProps: { min: 1 },
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                x {TSHIRT_PRICE}
                                                                € ={' '}
                                                                {(values.tshirtQuantity ??
                                                                    0) *
                                                                    TSHIRT_PRICE}
                                                                €
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        {isDanGradingAvailable && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="danGrading"
                                                            checked={
                                                                values.danGrading
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                        />
                                                    }
                                                    label="Dan Grading"
                                                />
                                            </Box>
                                        )}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                mb: 2,
                                                mt: 2,
                                            }}
                                        >
                                            <Typography
                                                color="primary"
                                                variant="h6"
                                            >
                                                Total: {values.totalPrice}€
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            {!registrationStatus.message && (
                                                <Button
                                                    type="submit"
                                                    disabled={disabled}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Register
                                                </Button>
                                            )}
                                        </Box>
                                        {registrationStatus.message && (
                                            <Alert
                                                severity="error"
                                                sx={{
                                                    marginTop: 2,
                                                    display: 'flex',
                                                    fontSize: '1rem',
                                                    alignItems: 'center',
                                                    '& .MuiAlert-message': {
                                                        width: '100%',
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        justifyContent:
                                                            'space-between',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Box>
                                                        {
                                                            registrationStatus.message
                                                        }
                                                    </Box>
                                                    <Button
                                                        type="submit"
                                                        disabled={disabled}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Register
                                                    </Button>
                                                </Box>
                                            </Alert>
                                        )}
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    )}
                </Container>
            </LocalizationProvider>
        </Box>
    );
};

export default Register;
